import { useMatomo } from '@datapunt/matomo-tracker-react';
import assert from 'assert';
import moment from 'moment';
import { useMemo, useState } from 'react';
import {
  selectExperimentsForProject,
  changeOrder,
  setSortyBy,
  changeHistory,
} from 'services/experiments';
import { setProjectStatus } from 'services/projects';
import { useDispatch, useSelector } from 'store';
import {
  ProjectStatus,
  Experiment,
  ExperimentResults,
  ParameterRecommendation,
} from 'types/api/types';
import { ExperimentResult } from 'types/app/types';
import { exportCSVFile } from 'util/csv';
import useProjectDetails from 'pages/Project/hooks/useProjectDetails';

const useHistory = (projectId: string) => {
  const dispatch = useDispatch();
  const { trackEvent } = useMatomo();
  const experiments = useSelector(selectExperimentsForProject(projectId));
  const { project } = useProjectDetails(projectId);

  const handleChangeOrder = (sortBy: string, order: 'asc' | 'desc') => {
    dispatch(changeOrder(order));
    dispatch(setSortyBy(sortBy));
  };
  const [history, setHistory] = useState<Experiment[]>([...experiments]);
  const [isEditing, setIsEditing] = useState(false);
  const [historyDelete, setHistoryDelete] = useState<
    { experimentId: string }[]
  >([]);
  const [historyModify, setHistoryModify] = useState<{
    [experimentId: string]: {
      experimentId: string;
      results: ExperimentResult;
      parameters: ParameterRecommendation;
    };
  }>({});

  const _experiments = useMemo(() => {
    return isEditing ? history : experiments;
  }, [history, experiments, isEditing]);

  const handleChangeHistory = () => {
    setIsEditing(true);
    setHistory(experiments);
  };
  const handleRemoveHistoryItem = (experimentId: string) => {
    setHistoryDelete((state) => [...state, { experimentId }]);
    setHistory((state) =>
      state.filter((item) => item.experimentId !== experimentId)
    );
  };
  const handleChangeHistoryItem = (item: {
    experimentId: string;
    results?: ExperimentResults;
    parameters?: ParameterRecommendation;
  }) => {
    const exisitingItem = historyModify[item.experimentId] || {
      experimentId: item.experimentId,
    };
    const exisitingResults = exisitingItem.results || {};
    const newResults = { ...exisitingResults, ...item?.results };
    const newParams = { ...exisitingItem.parameters, ...item.parameters };

    const newItem = {
      ...exisitingItem,
      results: newResults,
      parameters: newParams,
    };

    setHistoryModify((state) => ({ ...state, [item.experimentId]: newItem }));
    setHistory((state) =>
      state.map((i) => {
        if (i.experimentId === item.experimentId) return { ...i, ...newItem };
        return i;
      })
    );
  };

  const handleCancelHistory = () => {
    setHistory([...experiments]);
    setHistoryModify({});
    setHistoryDelete([]);
    setIsEditing(false);
  };
  const handleSaveHistory = () => {
    assert(projectId);
    dispatch(
      changeHistory({
        projectId,
        body: {
          delete: historyDelete,
          modify: Object.values(historyModify),
        },
      })
    );
    dispatch(setProjectStatus({ projectId, status: ProjectStatus.running }));
    setHistoryModify({});
    setHistoryDelete([]);
    setIsEditing(false);
  };
  const exportHistory = () => {
    trackEvent({
      category: 'project',
      action: 'export-results',
    });
    const list = experiments;
    if (!list || !list.length) return;
    const first = list[0];
    const headers = [
      'Batch',
      'Author',
      ...Object.keys(first.parameters),
      ...Object.keys(first.results),
    ];
    const rows = list.map((experiment) => ({
      Batch: experiment.batch,
      Author: experiment.changedBy?.email || '',
      ...experiment.parameters,
      ...experiment.results,
    }));

    const fileTitleParts = [
      'experiments',
      project?.name || first.projectId,
      moment().format('YYYYMMDDHHmm'),
      'history',
    ];

    exportCSVFile(headers, rows, `${fileTitleParts.join('_')}.csv`);
  };

  return {
    history: _experiments,
    isEditing,
    exportHistory,
    changeOrder: handleChangeOrder,
    startEditing: handleChangeHistory,
    removeExperiment: handleRemoveHistoryItem,
    changeExperiment: handleChangeHistoryItem,
    save: handleSaveHistory,
    cancel: handleCancelHistory,
  };
};

export default useHistory;
