import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from 'react';
import { nanoid } from 'nanoid';
import { useNavigate, Link } from 'react-router-dom';
import { LightBox } from '@liquid-design/liquid-design-react';
import {
  LdButton,
  LdAccordion,
  LdAccordionSection,
  LdAccordionPanel,
  LdAccordionToggle,
  LdTypo,
  LdBadge,
  LdModal,
  LdInputMessage,
} from '@emdgroup-liquid/liquid/dist/react';
import {
  Constraint,
  ConstraintContinuousOperatorType,
  ConstraintsSubTypeContinuous,
  ConstraintsSubTypeDiscrete,
  ConstraintsType,
  DiscreteConditions,
  ExpertOptions,
  Parameter,
  ProjectStatus,
  TargetVariable,
} from 'types/api/types';
import {
  ConstraintFormItem,
  ConstraintObject,
  FormError,
  ParametersObject,
  StateStatus,
} from 'types/app/types';
import AppLayout from '../../layouts/AppLayout';
import {
  AddParameterForm,
  ParameterDetails,
  SpaceForm,
} from '../../components/ChemicalSpace';

import { useDispatch, useSelector } from 'store';
import ExpertOptionsForm from 'components/ChemicalSpace/ExpertOptionsForm';
import useNotification, { NotificationType } from 'hooks/useNotification';
import { TargetsList } from 'components/ChemicalSpace/TargetList/TargetsList';
import { calculateCombinations } from 'util/project';
import { useHints } from 'hooks/useHints';
import Disclaimer from 'layouts/Disclaimer';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import useClientinfo from 'hooks/useClientinfo';
import { useProjectToClone } from 'pages/Project/hooks/useProjectToClone';
import { createProject } from 'services/projects';
import { SpaceFormRef } from 'components/ChemicalSpace/SpaceForm';
import AddConstraintsForm from 'components/ChemicalSpace/AddConstraintsForm';
import ConsraintDetails from 'components/ChemicalSpace/ConstraintDetails';
import useNotice from 'hooks/useNotice';

const { REACT_APP_COMBINATION_THRESHOLD = '40000' } = process.env;

const sortedId = () => {
  const timestamp = Date.now();
  return `${timestamp}_${nanoid()}`;
};

const CreatePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { wrapLabel } = useHints();
  const { showNotice } = useNotice();
  const { trackPageView, trackEvent } = useMatomo();
  const { isChrome } = useClientinfo();
  const spaceFormRef = useRef<SpaceFormRef>(null);
  const projectToClone = useProjectToClone();

  const modalRefConstr = useRef(null);
  const tetherOptionsConstraint: any = {
    bodyElement: modalRefConstr.current,
    constraints: [{ to: 'scrollParent' }],
  };

  useEffect(() => {
    if (!projectToClone) {
      return;
    }

    setProject({
      name: projectToClone.name,
      batchSize: projectToClone.batchSize,
      status: ProjectStatus.draft,
    });

    setTargets(
      projectToClone.targets.reduce<Record<string, TargetVariable>>(
        (acc, target) => {
          acc[target.targetName] = target;
          return acc;
        },
        {}
      )
    );
    setParameters(
      projectToClone.parameterSpace.reduce<Record<string, Parameter>>(
        (acc, parameter) => {
          acc[parameter.parameterName] = parameter;
          return acc;
        },
        {}
      )
    );
    setExpertOptions(projectToClone.expertOptions);

    const clonedConstraints: ConstraintObject = {};
    projectToClone.constraints.forEach((cons) => {
      const constraintId: string = nanoid();
      const parameters: Record<string, string> = {};
      const coefficients: Record<string, number> = {};
      const conditions: Record<string, DiscreteConditions | undefined> = {};
      const affectedParamters: Record<string, string[]> = {};
      const dependencies: {
        parameters: Record<string, string>;
        conditions: Record<string, DiscreteConditions | undefined>;
        affectedParamters: Record<string, string[]>;
      } = {
        parameters: {},
        conditions: {},
        affectedParamters: {},
      };

      // We use index because order is preserved in DB since we used Keys to save the data
      cons.parameters.forEach((p) => (parameters[p] = p));

      if (cons.type == ConstraintsType.continuous)
        cons.coefficients.forEach(
          (c, index) => (coefficients[parameters[index]] = c)
        );
      if (cons.type == ConstraintsType.discrete) {
        cons.conditions?.forEach(
          (c, index) => (conditions[parameters[index]] = c)
        );
        cons.affectedParameters?.forEach(
          (c, index) => (affectedParamters[parameters[index]] = c)
        );
      }
      if (
        cons.type == ConstraintsType.discrete &&
        cons.dependencies != undefined
      ) {
        cons?.dependencies?.parameters?.forEach(
          (p) => (dependencies.parameters[p] = p)
        );

        cons.dependencies.conditions?.forEach(
          (c, index) => (dependencies.conditions[parameters[index]] = c)
        );
        cons.dependencies.affectedParameters?.forEach(
          (c, index) => (dependencies.affectedParamters[parameters[index]] = c)
        );
      }

      const subType = cons.subType as
        | ConstraintsSubTypeContinuous
        | ConstraintsSubTypeDiscrete;
      const type: ConstraintsType = cons.type as ConstraintsType;

      clonedConstraints[constraintId] = {
        ...cons,
        conditions,
        coefficients,
        parameters,
        constraintId,
        type,
        subType,
        dependencies,
      } as ConstraintFormItem & {
        coefficients?: Record<string, number | undefined>;
        parameters: Record<string, string>;
        conditions?: Record<string, DiscreteConditions | undefined>;
        affectedParamters?: Record<string, string[] | undefined>;
        dependencies?: {
          parameters: Record<string, string>;
          affectedParamters: Record<string, string[] | undefined>;
          conditions: Record<string, DiscreteConditions | undefined>;
        };
      };
    });
    setConstriants(clonedConstraints);
  }, [projectToClone]);

  useEffect(() => {
    trackPageView({
      documentTitle: 'New Project',
    });
  }, []);

  type ModalType =
    | 'parameter'
    | 'combinationWarning'
    | 'batchSizeWarning'
    | 'constraints';

  const { status, error } = useSelector((state) => state.projects);
  const [modal, setModal] = useState<ModalType | null>(null);
  const [project, setProject] = useState<
    | {
        name: string;
        batchSize: number;
        status?: string;
      }
    | undefined
  >({ status: ProjectStatus.draft, name: '', batchSize: 0 });
  const [parameters, setParameters] = useState<ParametersObject>({});
  const [constraints, setConstriants] = useState<ConstraintObject>({});
  const [targets, setTargets] = useState<{
    [key: string]: TargetVariable;
  }>({});
  const [expertOptions, setExpertOptions] = useState<ExpertOptions>();
  const { clearNotifications, sendNotification } = useNotification();
  const [editParameter, setEditParameter] = useState<string | null>(null);
  const [editConstraint, setEditConstraints] = useState<string | null>(null);
  const isLoading = status === StateStatus.loading;

  const handleEditParameter = (id: string) => {
    setEditParameter(id);
    setModal('parameter');
  };

  const handleEditConstraint = (id: string) => {
    setEditConstraints(id);
    setModal('constraints');
  };

  const targetList = useMemo(() => Object.values(targets), [targets]);

  const combinationAmount: number = useMemo(() => {
    return calculateCombinations(
      Object.values(parameters),
      extractConstraints()
    );
  }, [parameters, constraints]);

  const handleAddParameter = (parameter: Parameter) => {
    const isEditing = !!editParameter;
    const existingName = Object.entries(parameters).find(
      ([key, { parameterName }]) =>
        parameter.parameterName === parameterName && key !== editParameter
    );
    if (existingName) {
      throw new FormError('parameterName', 'Parameter names must be unique.');
    }
    setParameters((old) => {
      const parameterId = isEditing ? editParameter : nanoid();
      return { ...old, [parameterId]: parameter };
    });
    setEditParameter(null);
    setModal(null);
  };

  const handleAddConstraint = (
    constraint: ConstraintFormItem & {
      coefficients?: Record<string, number | undefined>;
      parameters: Record<string, string>;
      affectedParamters?: Record<string, string[] | undefined>;
      dependencies?: {
        parameters: Record<string, string>;
        affectedParamters: Record<string, string[] | undefined>;
        conditions: Record<string, DiscreteConditions | undefined>;
      };
    }
  ) => {
    const isEditing = !!editConstraint;
    setConstriants((old) => {
      const id = isEditing ? editConstraint : nanoid();
      return {
        ...old,
        [id]: { ...constraint, constraintId: id },
      };
    });
    setEditConstraints(null);
    setModal(null);
  };

  const checkIfParamUsedConstraints = (id: string) => {
    return Object.entries(constraints).map(([key, val]) => {
      if (Object.keys(val.parameters).includes(id))
        return [val.subType, parameters[id].parameterName];

      if (val.conditions && Object.keys(val.conditions).includes(id))
        return [val.subType, parameters[id].parameterName];

      if (
        val.affectedParamters &&
        Object.keys(val.affectedParamters).includes(id)
      )
        return [val.subType, parameters[id].parameterName];
      if (
        val.dependencies &&
        Object.keys(val.dependencies.parameters).includes(id)
      )
        return [val.subType, parameters[id].parameterName];
      if (
        val.dependencies &&
        Object.keys(val.dependencies.affectedParamters).includes(id)
      )
        return [val.subType, parameters[id].parameterName];
    });
  };

  const handleRemoveParameter = (id: string) => {
    const [consType] = checkIfParamUsedConstraints(id);
    if (!consType) {
      setParameters(({ ...old }) => {
        delete old[id];
        return old;
      });
    } else {
      sendNotification({
        content: `This parameter <b>${consType[1]}</b> is used for constraints <u>${consType[0]}</u> and cannot be deleted!`,
        type: NotificationType.alert,
        timeout: 10000,
      });
    }
  };

  const handleRemoveConstraint = (id: string) => {
    setConstriants(({ ...old }) => {
      delete old[id];
      return old;
    });
  };

  const handleAddTarget = (target: TargetVariable) => {
    setTargets((old) => ({ ...old, [sortedId()]: target }));
  };

  const handleEditTarget = (targetKey: string, target: TargetVariable) => {
    setTargets((old) => ({ ...old, [targetKey]: target }));
  };

  const handleRemoveTarget = (id: string) => {
    setTargets((old) => {
      delete old[id];
      return { ...old };
    });
  };

  const isSubmitDisabled = useMemo(() => {
    const targetKeys: Array<keyof TargetVariable> = [
      'targetName',
      'targetObjective',
      'targetWeight',
      'lowerBound',
      'upperBound',
    ];

    const targetsHaveError =
      Object.keys(targets).length > 1 &&
      Object.values(targets).some((target) => {
        return targetKeys.some(
          (key) => target[key] === undefined || !`${target[key]}`
        );
      });

    return targetsHaveError;
  }, [targets]);

  const handleCreateProject = async (p: {
    name: string;
    batchSize: number;
  }) => {
    if (!targetList.length) return;
    if (combinationAmount <= p.batchSize) {
      setProject(p);
      setModal('batchSizeWarning');
      spaceFormRef.current?.setError('batchSize', {
        message: `Must be smaller than ${combinationAmount}`,
      });
    } else if (
      combinationAmount < Infinity &&
      combinationAmount > parseInt(REACT_APP_COMBINATION_THRESHOLD)
    ) {
      setProject(p);
      setModal('combinationWarning');
    } else {
      submitCreation(p);
    }
  };

  function extractConstraints(): Constraint[] {
    return Object.values(constraints).map((constr) => {
      if (constr.type === ConstraintsType.continuous) {
        const keys = Object.keys(parameters);

        const returnVal: any = {
          type: constr.type,
          subType: constr.subType,
          rhs: constr.rhs,
          operator: constr.operator,
          coefficients: [],
          parameters: [],
        };

        keys.forEach((k) => {
          if (constr.parameters[k]) {
            returnVal.coefficients.push(constr.coefficients?.[k]);
            returnVal.parameters.push(constr.parameters[k]);
          }
        });
        if (constr.operator === ConstraintContinuousOperatorType['>=']) {
          returnVal['rhs'] = -returnVal['rhs'];
          returnVal['coefficients'] = returnVal['coefficients'].map(
            (c: number) => -c
          );
        }
        return returnVal;
      } else {
        const keys = Object.keys(parameters);

        const returnVal: any = {
          type: constr.type,
          subType: constr.subType,
          parameters: [],
        };

        if (
          constr.subType ==
            ConstraintsSubTypeDiscrete.DiscreteProductConstraint ||
          constr.subType == ConstraintsSubTypeDiscrete.DiscreteSumConstraint
        ) {
          if (constr.conditions)
            returnVal['conditions'] = Object.values(constr.conditions);
        }
        if (
          constr.subType == ConstraintsSubTypeDiscrete.DiscreteExcludeConstraint
        ) {
          returnVal['combiner'] = constr.combiner;
          returnVal['conditions'] = [];
        }

        if (
          constr.subType ==
          ConstraintsSubTypeDiscrete.DiscreteDependenciesConstraint
        ) {
          returnVal['conditions'] = [];
          returnVal['affectedParameters'] = [];
        }

        keys.forEach((k) => {
          if (constr.parameters[k]) {
            returnVal.parameters.push(constr.parameters[k]);

            if (
              constr.subType ==
              ConstraintsSubTypeDiscrete.DiscreteExcludeConstraint
            ) {
              returnVal.conditions.push(constr.conditions?.[k]);
            }

            if (
              constr.subType ==
              ConstraintsSubTypeDiscrete.DiscreteDependenciesConstraint
            ) {
              returnVal.conditions.push(constr.conditions?.[k]);
              returnVal.affectedParameters.push(constr.affectedParamters?.[k]);
            }
          }
        });

        if (
          constr.subType ==
            ConstraintsSubTypeDiscrete.DiscretePermutationInvarianceConstraint &&
          constr.dependencies != undefined
        ) {
          // dependencies is optional
          returnVal['dependencies'] = {
            type: constr.dependencies.type,
            conditions: [],
            affectedParameters: [],
            parameters: [],
          };

          const innerKeys = Object.keys(constr.dependencies?.parameters);
          innerKeys.forEach((k) => {
            if (constr.dependencies?.parameters[k]) {
              returnVal.dependencies.parameters.push(
                constr.dependencies?.parameters[k]
              );
              returnVal.dependencies.conditions.push(
                constr.dependencies?.conditions?.[k]
              );
            }
          });

          returnVal.dependencies.affectedParameters = Object.values(
            constr.dependencies?.affectedParamters
          );
        }

        return returnVal;
      }
    });
  }

  const submitCreation = async (project: {
    name: string;
    batchSize: number;
  }) => {
    trackEvent({ category: 'project', action: 'create-project' });
    clearNotifications();
    setModal(null);
    setProject(undefined);
    try {
      const response = await dispatch(
        createProject({
          ...project,
          targets: targetList,
          parameterSpace: Object.values(parameters),
          expertOptions,
          constraints: extractConstraints(),
        })
      );
      if (error) {
        console.log('error catched' + error);
        sendNotification({ content: error, type: NotificationType.alert });
      }
      if (response.payload) navigate(`/projects/${response.payload.projectId}`);
    } catch (err) {
      let errorText = typeof err === 'string' ? err : (err as Error).toString();
      sendNotification({ content: errorText, type: NotificationType.alert });
    }
  };

  return (
    <AppLayout>
      <div id="upload" className="relative flex max-w-[1024px] mx-auto">
        <div className="relative flex flex-col items-start w-full max-h-screen min-h-screen pt-8 pl-4">
          {!isChrome && <Disclaimer />}
          {showNotice()}
          <div className="flex justify-start items-center mb-5 gap-ld-4 pt-ld-4">
            <Link to={`/projects`} className="flex hover:underline">
              <LdTypo variant="body-s">Create Project</LdTypo>
            </Link>
          </div>
          <SpaceForm
            ref={spaceFormRef}
            onSubmit={handleCreateProject}
            isSubmitDisabled={isSubmitDisabled}
            loading={isLoading}
            parameterCount={Object.keys(parameters).length}
            targetsCount={Object.keys(targets).length}
            possibleCombinations={combinationAmount}
            projectToClone={projectToClone}
            actionArea={
              <div>
                <LdButton
                  type="button"
                  mode="ghost"
                  onClick={() => setModal('parameter')}
                  disabled={isLoading}
                >
                  <span className="text-xl font-light">+</span>
                  Add Parameter
                </LdButton>

                <LdButton
                  type="button"
                  mode="ghost"
                  onClick={() => setModal('constraints')}
                  disabled={isLoading || !Object.keys(parameters).length}
                >
                  <span className="text-xl font-light">+</span>
                  Add Constraints
                </LdButton>
              </div>
            }
          >
            <div className="flex w-full flex-col">
              <TargetsList
                targets={targets}
                onRemoveTarget={handleRemoveTarget}
                onAddTarget={handleAddTarget}
                onEditTarget={handleEditTarget}
              />

              <LdAccordion rounded detached tone="dark">
                <LdAccordionSection>
                  <LdAccordionToggle>
                    {wrapLabel('Cre_5', 'Advanced Settings')}
                  </LdAccordionToggle>
                  <LdAccordionPanel>
                    <div className="py-ld-16 p-ld-24">
                      <ExpertOptionsForm
                        projectToClone={projectToClone}
                        onChange={setExpertOptions}
                      />
                    </div>
                  </LdAccordionPanel>
                </LdAccordionSection>
              </LdAccordion>
            </div>
          </SpaceForm>
          {Object.keys(parameters).length > 0 && (
            <LdTypo variant="h3" className="mb-ld-8 mt-ld-16">
              Parameters
              {/* {isDeleteBlocked && <LdInputMessage>This paramter is used in constraints.</LdInputMessage>} */}
            </LdTypo>
          )}
          <div className="flex flex-col gap-ld-16 w-full pb-ld-32">
            {Object.entries(parameters).map(([key, parameter]) => {
              return (
                <ParameterDetails
                  key={key}
                  id={key}
                  {...parameter}
                  onDelete={() => handleRemoveParameter(key)}
                  onEdit={() => handleEditParameter(key)}
                />
              );
            })}
          </div>
          {Object.keys(constraints).length > 0 && (
            <LdTypo variant="h3" className="mb-ld-8 mt-ld-16">
              Constraints
            </LdTypo>
          )}
          <div className="flex flex-col gap-ld-16 w-full pb-ld-32">
            {Object.entries(constraints).map(([key, constraint]) => {
              return (
                <ConsraintDetails
                  key={key}
                  constraint={constraint}
                  onDelete={() => handleRemoveConstraint(key)}
                  onEdit={() => handleEditConstraint(key)}
                />
              );
            })}
          </div>
        </div>
        <LightBox
          onClose={() => setModal(null)}
          open={modal === 'parameter'}
          label="Add Parameter"
        >
          <div className="relative flex w-full max-h-[60rem] flex-grow min-h-[18rem]">
            <AddParameterForm
              projectStatus={project?.status}
              onCancel={() => {
                setEditParameter(null);
                setModal(null);
              }}
              data-test="upload-card-drop"
              // className={`left-0 right-0 ml-auto mr-auto absolute transform ease-out transition`}
              onSubmit={handleAddParameter}
              parameter={editParameter ? parameters[editParameter] : undefined}
              values={
                editParameter
                  ? parameters[editParameter].parameterValues
                  : undefined
              }
            />
          </div>
        </LightBox>
        <LdModal
          open={modal === 'constraints'}
          onLdmodalclosing={() => setModal(null)}
          cancelable={false}
          blurryBackdrop
          style={{
            '--ld-modal-min-inline-size': '60rem',
          }}
          ref={modalRefConstr}
        >
          <div slot="header" className="flex items-center gap-ld-4 py-ld-8">
            <LdTypo variant="h4">Add Constraint</LdTypo>
          </div>
          <div className="relative flex max-h-[60rem] w-full">
            <AddConstraintsForm
              projectStatus={project?.status}
              onCancel={() => {
                setEditConstraints(null);
                setModal(null);
              }}
              onSubmit={handleAddConstraint}
              editConstraint={
                editConstraint ? constraints[editConstraint] : undefined
              }
              selectedParams={parameters}
              tetherOptions={tetherOptionsConstraint}
            />
          </div>
        </LdModal>
        <LdModal cancelable={false} open={modal === 'combinationWarning'}>
          <div slot="header" className="flex items-center gap-ld-4 py-ld-8">
            <LdBadge icon="attention" size="lg" class="ld-badge--warning" />
            <LdTypo variant="h4">Warning</LdTypo>
          </div>
          <div className="relative flex w-full flex-col gap-ld-4">
            <LdTypo>
              With the given parameters and their values there are{' '}
              {combinationAmount} possible combinations. It is generally
              expected that at least 1% of them (corresponding to{' '}
              {Math.round(combinationAmount / 100)} for the current parameter
              space) have to be measued to get the underlying model to
              understand and become useful. Consider reducing or redesigning the
              parameter space.
            </LdTypo>
            <div
              slot="footer"
              className="flex flex-row items-end justify-end gap-x-ld-8"
            >
              <LdButton onClick={() => setModal(null)} mode="secondary">
                Go Back
              </LdButton>
              <LdButton
                onClick={() => submitCreation(project!)}
                mode="highlight"
              >
                Ignore
              </LdButton>
            </div>
          </div>
        </LdModal>
        <LdModal cancelable={false} open={modal === 'batchSizeWarning'}>
          <div slot="header" className="flex items-center gap-ld-4 py-ld-8">
            <LdBadge icon="attention" size="lg" class="ld-badge--danger" />
            <LdTypo variant="h4">Error</LdTypo>
          </div>
          <div className="relative flex w-full flex-col gap-ld-4">
            <LdTypo>
              You are requesting {project?.batchSize} recommendations per batch,
              which exceeds the number of {combinationAmount} possible
              combinations. Change &quot;Recommendations per Batch&quot; to a
              number smaller than {combinationAmount}.
            </LdTypo>
            <div
              slot="footer"
              className="flex flex-row items-end justify-end gap-x-ld-8"
            >
              <LdButton onClick={() => setModal(null)} mode="secondary">
                Got it!
              </LdButton>
            </div>
          </div>
        </LdModal>
      </div>
    </AppLayout>
  );
};

export default CreatePage;
