/* eslint-disable react-hooks/exhaustive-deps */

import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useForm } from 'react-hook-form';

import {
  LdBadge,
  LdButton,
  LdIcon,
  LdInput,
  LdInputMessage,
  LdLabel,
  LdOption,
  LdSelect,
  LdSwitch,
  LdSwitchItem,
  LdTable,
  LdTableBody,
  LdTableCell,
  LdTableHead,
  LdTableHeader,
  LdTableRow,
  LdTypo,
} from '@emdgroup-liquid/liquid/dist/react';
import {
  CombinerType,
  ConstraintContinuousOperatorType,
  ConstraintsSubTypeDiscrete,
  ConstraintsType,
  DiscreteConditions,
  ParameterType,
  SubSelectionCondition,
  ThresholdCondition,
} from 'types/api/types';
import {
  ConstraintDiscreteSubtypeSimple,
  ConstraintFormItem,
  ConstraintSubTypesLabel,
  ConstraintTypesLabel,
  ConstraintsSubtypLabels,
  ParametersObject,
} from 'types/app/types';
// eslint-disable-next-line import/named
import { LdSelectCustomEvent } from '@emdgroup-liquid/liquid';
import Tag from 'components/Tag';
import { nanoid } from 'nanoid';
import { ConstraintsValueForm } from './ConstraintsValue/ConstraintsValueForm';
import SingleTresholdConditionForm from './ConstraintsValue/SingleTresholdConditionForm';

interface Props {
  onSubmit: (
    arg0: ConstraintFormItem & {
      coefficients?: Record<string, number | undefined>;
      parameters: Record<string, string>;
      conditions?: Record<string, DiscreteConditions | undefined>;
    }
  ) => void;
  onCancel: () => void;
  editConstraint?: {
    subType?: string;
    type: ConstraintsType;
    constraintId: string;
    projectId?: string;
    rhs?: number;
    operator?: ConstraintContinuousOperatorType;
    combiner?: CombinerType;
  } & Pick<
    ConstraintFormItem,
    | 'parameters'
    | 'coefficients'
    | 'conditions'
    | 'affectedParamters'
    | 'dependencies'
  >;
  selectedParams: ParametersObject;
  projectStatus?: string;
  tetherOptions: any;
}

const AddConstraintsForm: React.FC<Props> = ({
  onSubmit,
  onCancel,
  editConstraint,
  selectedParams,
  tetherOptions,
}) => {
  const { formState, setValue, trigger, register, clearErrors } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      errorMessages: '',
    },
  });
  const { errors } = formState;
  const formRef = useRef<HTMLFormElement>(null);
  const [parameterSubType, setParameterSubType] = useState<string | undefined>(
    editConstraint?.subType
  );
  const [parameterType, setParameterType] = useState<
    'continuous' | 'discrete' | undefined
  >(editConstraint?.type);
  const [rhs, setRhs] = useState<number | undefined>(editConstraint?.rhs);
  const [operator, setOperator] = useState<string | undefined>(
    editConstraint?.operator
  );
  const [combiner, setCombiner] = useState<CombinerType | undefined>(
    editConstraint?.['combiner'] || CombinerType.AND
  );
  const [entries, setEntries] = useState<Record<string, string | undefined>>(
    {}
  ); // paramter names
  const [permutationParams, setPermutationParams] = useState<
    Record<string, string | undefined>
  >({}); // paramter names permutation

  const [coefficients, setCoefficients] = useState<
    Record<string, number | undefined>
  >({});
  const [conditions, setConditions] = useState<
    Record<string, DiscreteConditions | undefined>
  >({});
  const [affectedParamters, setAffectedParamters] = useState<
    Record<string, string[] | undefined>
  >({});

  const updateSingleCondition = (value: ThresholdCondition) => {
    const key = Object.keys(conditions)[0];
    setConditions({ [key]: value });
  };

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const isValid = await trigger();
    if (!isValid) return;

    let constraint: ConstraintFormItem = {
      constraintId: editConstraint?.constraintId,
      projectId: editConstraint?.projectId,
      type: parameterType,
      parameters: entries,
      subType: parameterSubType,
    } as ConstraintFormItem;

    if (parameterType == ConstraintsType.continuous) {
      constraint = constraint as ConstraintFormItem & {
        coefficients?: Record<string, number>;
        parameters: Record<string, string>;
      };
      constraint['coefficients'] = coefficients;
      constraint['rhs'] = rhs as number;
      constraint['operator'] = operator as ConstraintContinuousOperatorType;
    } else {
      constraint = constraint as ConstraintFormItem & {
        parameters: Record<string, string>;
        conditions: Record<string, DiscreteConditions>;
      };
      if (combiner) constraint['combiner'] = combiner as CombinerType;
      constraint['conditions'] = conditions;
      if (Object.values(affectedParamters)?.length > 0)
        constraint['affectedParamters'] = affectedParamters;
      if (
        permutationParams != undefined &&
        Object.values(permutationParams)?.length > 0
      ) {
        constraint['dependencies'] = {
          type: 'DiscreteDependenciesConstraint',
          parameters: permutationParams as Record<string, string>,
          conditions: conditions,
          affectedParamters: affectedParamters,
        };
      }
    }

    onSubmit(constraint);
    handleFormInvalid();
  };

  const addConstraintValue = (val: Record<string, any>) => {
    if (parameterType == ConstraintsType.continuous) {
      setEntries((old) => ({
        ...old,
        ...val.entries,
      }));
      setCoefficients((old) => ({
        ...old,
        ...val.coefficients,
      }));
    } else {
      if (
        parameterSubType ==
        ConstraintsSubTypeDiscrete.DiscretePermutationInvarianceConstraint
      ) {
        setPermutationParams((old) => ({
          ...old,
          ...val.entries,
        }));
      } else {
        setEntries((old) => ({
          ...old,
          ...val.entries,
        }));
      }
      if (
        parameterSubType ==
        ConstraintsSubTypeDiscrete.DiscreteDependenciesConstraint
      ) {
        setAffectedParamters((old) => ({
          ...old,
          ...val.affectedParamters,
        }));
      }

      setConditions((old) => ({
        ...old,
        ...val.conditions,
      }));
    }
  };

  const handleFormInvalid = React.useCallback(() => {
    setParameterType(undefined);
    setParameterSubType(undefined);
    setRhs(undefined);
    setOperator(undefined);
    setCombiner(undefined);
    setCoefficients({});
    setConditions({});
    setEntries({});
    setPermutationParams({});
    setAffectedParamters({});
    setValue('errorMessages', '');
  }, []);

  const handleCancel = () => {
    clearErrors('errorMessages');
    handleFormInvalid();
    onCancel();
  };

  const handleParameterTypeChange = (event: LdSelectCustomEvent<string[]>) => {
    if (!editConstraint?.type) {
      setEntries({});
      setAffectedParamters({});
      setCoefficients({});
      setConditions({});
      setCombiner(undefined);
      setRhs(undefined);
      setOperator(undefined);
      setParameterSubType(undefined);
      setPermutationParams({});
      setParameterType(event.detail[0] as any);
    }
  };

  useEffect(() => {
    if (editConstraint?.conditions)
      Object.entries(editConstraint.conditions).forEach(([key, val]) => {
        if (val)
          setConditions((old) => ({
            ...old,
            [key]: val,
          }));
      });
  }, [editConstraint?.conditions]);

  useEffect(() => {
    if (editConstraint?.dependencies) {
      Object.entries(editConstraint.dependencies?.conditions)?.forEach(
        ([key, val]) => {
          if (val)
            setConditions((old) => ({
              ...old,
              [key]: val,
            }));
        }
      );
      Object.entries(editConstraint?.dependencies?.parameters)?.forEach(
        ([key, val]) => {
          if (val != undefined)
            setPermutationParams((old) => ({
              ...old,
              [key]: val,
            }));
        }
      );
      Object.entries(editConstraint?.dependencies?.affectedParamters)?.forEach(
        ([key, val]) => {
          if (val != undefined)
            setAffectedParamters((old) => ({
              ...old,
              [key]: val,
            }));
        }
      );
    }
  }, [editConstraint?.dependencies]);

  useEffect(() => {
    if (editConstraint?.parameters)
      Object.entries(editConstraint?.parameters).forEach(([key, val]) => {
        if (val != undefined)
          setEntries((old) => ({
            ...old,
            [key]: val,
          }));
      });
  }, [editConstraint?.parameters]);

  useEffect(() => {
    if (editConstraint?.affectedParamters)
      Object.entries(editConstraint?.affectedParamters).forEach(
        ([key, val]) => {
          if (val != undefined)
            setAffectedParamters((old) => ({
              ...old,
              [key]: val,
            }));
        }
      );
  }, [editConstraint?.affectedParamters]);

  useEffect(() => {
    if (editConstraint?.coefficients)
      Object.entries(editConstraint?.coefficients).forEach(([key, val]) => {
        if (val != undefined)
          setCoefficients((old) => ({
            ...old,
            [key]: val,
          }));
      });
  }, [editConstraint?.coefficients]);

  useEffect(() => {
    if (editConstraint) {
      setParameterSubType(editConstraint.subType);
      setParameterType(editConstraint.type);
      setRhs(editConstraint.rhs);
      setOperator(editConstraint.operator);
      setCombiner(editConstraint.combiner);
    }
  }, [editConstraint]);

  const valueOptions: ParametersObject = useMemo(() => {
    if (!selectedParams) return {};

    if (parameterType === ConstraintsType.continuous) {
      return Object.fromEntries(
        Object.entries(selectedParams).filter(
          ([key, value]) =>
            value.parameterType == ParameterType.continuous &&
            !Object.values(entries).includes(value.parameterName)
        )
      );
    }

    if (parameterType === ConstraintsType.discrete) {
      if (
        parameterSubType ==
          ConstraintsSubTypeDiscrete.DiscreteLinkedParametersConstraint ||
        parameterSubType ==
          ConstraintsSubTypeDiscrete.DiscreteNoLabelDuplicatesConstraint
      ) {
        // LABEL LIKE paramss
        return Object.fromEntries(
          Object.entries(selectedParams).filter(
            ([key, value]) =>
              value.parameterType !== ParameterType.continuous &&
              value.parameterType !== ParameterType.numeric &&
              value.parameterType !== ParameterType.task
          )
        );
      }

      if (
        parameterSubType == ConstraintsSubTypeDiscrete.DiscreteSumConstraint ||
        parameterSubType == ConstraintsSubTypeDiscrete.DiscreteProductConstraint
      ) {
        // NUMERIC LIKE paramss
        return Object.fromEntries(
          Object.entries(selectedParams).filter(
            ([key, value]) => value.parameterType == ParameterType.numeric
          )
        );
      }
      if (
        parameterSubType ==
        ConstraintsSubTypeDiscrete.DiscretePermutationInvarianceConstraint
      ) {
        return Object.fromEntries(
          Object.entries(selectedParams).filter(
            ([key, value]) =>
              value.parameterType !== ParameterType.continuous &&
              value.parameterType !== ParameterType.task &&
              !Object.values(entries).includes(value.parameterName) &&
              !Object.values(permutationParams).includes(value.parameterName)
          )
        );
      }
      // all discrete params
      return Object.fromEntries(
        Object.entries(selectedParams).filter(
          ([key, value]) =>
            value.parameterType !== ParameterType.continuous &&
            value.parameterType !== ParameterType.task &&
            !Object.values(entries).includes(value.parameterName)
        )
      );
    }
    return {};
  }, [
    parameterType,
    selectedParams,
    entries,
    parameterSubType,
    permutationParams,
  ]);

  const dependenciesParamOptions = useMemo(() => {
    if (!selectedParams) return {};

    if (
      parameterType == ConstraintsType.discrete &&
      parameterSubType ==
        ConstraintsSubTypeDiscrete.DiscretePermutationInvarianceConstraint
    ) {
      return Object.fromEntries(
        Object.entries(selectedParams).filter(
          ([key, value]) =>
            value.parameterType !== ParameterType.continuous &&
            value.parameterType !== ParameterType.task &&
            !Object.values(permutationParams).includes(value.parameterName)
        )
      );
    }
    return {};
  }, [parameterSubType, selectedParams, parameterType, permutationParams]);

  useEffect(() => {
    if (!parameterType) {
      setValue('errorMessages', 'Please select type!');
      return;
    }
    if (!parameterSubType) {
      setValue('errorMessages', 'Please select subtype!');
      return;
    }
    if (!Object.values(entries).length) {
      setValue('errorMessages', 'Please select parameters!');
      return;
    }

    if (!Object.values(entries).filter((e) => e != undefined).length) {
      setValue('errorMessages', 'Please add parameters!');
      return;
    }

    if (parameterType == ConstraintsType.continuous) {
      if (!rhs) {
        setValue('errorMessages', 'Please set Right-Hand Side!');
        return;
      }
      if (!operator) {
        setValue('errorMessages', 'Please set operator!');
        return;
      }
      if (!Object.values(coefficients).length) {
        setValue('errorMessages', 'Please add coeffients!');
        return;
      }
      if (!Object.values(coefficients).filter((e) => e != undefined).length) {
        setValue('errorMessages', 'Please add coeffients!');
        return;
      }
      if (Object.values(coefficients).length != Object.values(entries).length) {
        setValue(
          'errorMessages',
          'Number of coefficients and number of selected paramters should be equal!'
        );
        return;
      }
    } else if (parameterType == ConstraintsType.discrete) {
      if (ConstraintDiscreteSubtypeSimple.includes(parameterSubType)) {
        if (Object.values(entries).length < 2) {
          setValue('errorMessages', 'Please select at least two parameters!');
          return;
        }
      }

      if (
        parameterSubType ==
          ConstraintsSubTypeDiscrete.DiscreteDependenciesConstraint ||
        parameterSubType ==
          ConstraintsSubTypeDiscrete.DiscreteExcludeConstraint ||
        parameterSubType == ConstraintsSubTypeDiscrete.DiscreteSumConstraint ||
        parameterSubType == ConstraintsSubTypeDiscrete.DiscreteProductConstraint
      ) {
        if (!Object.values(conditions).length) {
          setValue('errorMessages', 'Please add conditions!');
          return;
        }
        if (!Object.values(conditions).filter((e) => e != undefined).length) {
          setValue('errorMessages', 'Please add conditions!');
          return;
        }
      }
      if (
        parameterSubType ==
        ConstraintsSubTypeDiscrete.DiscretePermutationInvarianceConstraint
      ) {
        if (Object.keys(permutationParams)?.length) {
          if (!Object.values(permutationParams)?.length) {
            setValue('errorMessages', 'Please set dependencies!');
            return;
          }
          if (
            !Object.values(permutationParams).filter((e) => e != undefined)
              .length
          ) {
            setValue('errorMessages', 'Please add dependencies!');
            return;
          }
          if (
            Object.values(entries).length != Object.values(conditions).length
          ) {
            setValue(
              'errorMessages',
              'Please set dependencies for all parameters!'
            );
            return;
          }
        }
      }
      if (
        parameterSubType == ConstraintsSubTypeDiscrete.DiscreteExcludeConstraint
      ) {
        if (!combiner) {
          setValue('errorMessages', 'Please select combiner!');
          return;
        }
      }

      if (
        parameterSubType ==
        ConstraintsSubTypeDiscrete.DiscreteDependenciesConstraint
      ) {
        if (Object.values(affectedParamters).length == 0) {
          setValue('errorMessages', 'Please select affected paramters!');
          return;
        }
      }
    }
    setValue('errorMessages', 'valid!');
    clearErrors('errorMessages');
  }, [
    parameterType,
    parameterSubType,
    coefficients,
    rhs,
    entries,
    operator,
    conditions,
    combiner,
    affectedParamters,
    permutationParams,
  ]);

  const removeConstraints = (key: string) => {
    setEntries((old) => {
      delete old[key];
      return { ...old };
    });
    setPermutationParams((old) => {
      delete old[key];
      return { ...old };
    });
    setAffectedParamters((old) => {
      delete old[key];
      return { ...old };
    });
    setCoefficients((old) => {
      delete old[key];
      return { ...old };
    });
  };

  useEffect(() => {
    if (!editConstraint) {
      setEntries({});
      setConditions({});
      setAffectedParamters({});
      setPermutationParams({});
    }
  }, [parameterSubType, editConstraint]);

  useEffect(() => {
    if (
      selectedParams &&
      parameterSubType ==
        ConstraintsSubTypeDiscrete.DiscretePermutationInvarianceConstraint
    ) {
      const params: Record<string, string[] | undefined> = Object.fromEntries(
        Object.entries(entries)
          .filter(([key, value]) => value != undefined)
          .map(([key, val]) => [key, [val as string]])
      );
      setAffectedParamters(params);
    }
  }, [entries]);

  function handleSubTypeChange(e: LdSelectCustomEvent<string[]>): void {
    setParameterSubType(e.detail[0]);
    if (
      e.detail[0] == ConstraintsSubTypeDiscrete.DiscreteSumConstraint ||
      e.detail[0] == ConstraintsSubTypeDiscrete.DiscreteProductConstraint
    ) {
      const singleDiscrete = {
        [nanoid()]: {
          tolerance: undefined,
          operator: undefined,
          threshold: undefined,
        },
      };
      setConditions(singleDiscrete as Record<string, DiscreteConditions>);
    }
  }

  const rows = useMemo(
    () =>
      parameterSubType ===
      ConstraintsSubTypeDiscrete.DiscretePermutationInvarianceConstraint
        ? Object.entries(permutationParams)
        : Object.entries(entries),
    [parameterSubType, permutationParams, entries]
  );

  return (
    <div className="flex w-full flex-col h-full">
      <form
        autoComplete="off"
        className="flex w-full flex-col mb-3"
        ref={formRef}
        onKeyDown={(e) => {
          e.key === 'Enter' && e.preventDefault();
        }}
      >
        <div className="mb-2">
          <input
            type="hidden"
            {...register('errorMessages', {
              required: true,
              validate: (val) => val == 'valid!',
            })}
          />
          <LdInputMessage
            className={
              errors.errorMessages ? 'visible d-inline' : 'invisible d-none '
            }
            mode={errors.errorMessages ? 'error' : 'valid'}
          >
            {errors.errorMessages?.ref?.value || 'Please check the form!'}
          </LdInputMessage>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-ld-24 mb-5">
          <LdLabel>
            <span className="flex justify-between">Constraint Type</span>
            <LdSelect
              required
              disabled={editConstraint !== undefined}
              onLdinput={handleParameterTypeChange}
              placeholder="Select"
              name="param-type-select"
              tetherOptions={tetherOptions}
            >
              {Object.keys(ConstraintsType).map((val) => (
                <LdOption
                  key={val}
                  value={val}
                  selected={parameterType === val}
                >
                  {ConstraintTypesLabel.get(val)}
                </LdOption>
              ))}
            </LdSelect>
          </LdLabel>
          {parameterType != undefined && (
            <LdLabel>
              <span className="flex justify-between">Subtype</span>
              <LdSelect
                required
                disabled={editConstraint !== undefined}
                onLdinput={handleSubTypeChange}
                name="param-subtype-select"
                tetherOptions={tetherOptions}
                placeholder="Select Subtype"
              >
                {ConstraintSubTypesLabel.get(parameterType)?.map((val) => (
                  <LdOption
                    key={val}
                    value={val}
                    selected={parameterSubType === val}
                  >
                    {ConstraintsSubtypLabels[val]}
                  </LdOption>
                ))}
              </LdSelect>
            </LdLabel>
          )}
        </div>

        {parameterType &&
          parameterSubType &&
          parameterSubType ==
            ConstraintsSubTypeDiscrete.DiscretePermutationInvarianceConstraint && (
            <div className="my-1">
              <div className="flex flex-wrap gap-ld-4 mb-ld-4">
                {Object.entries(entries)?.map(([key, val]) => {
                  return <Tag key={key}>{val}</Tag>;
                })}
              </div>
              <LdLabel className="flex flex-grow">
                Parameters
                <LdSelect
                  tetherOptions={tetherOptions}
                  placeholder="Select parameters"
                  name="parameters"
                  onLdinput={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();

                    const keys: string[] = ev.detail;
                    if (keys?.length > 0) {
                      const newEntries = keys.reduce((acc, k) => {
                        acc[k] = dependenciesParamOptions[k].parameterName;
                        return acc;
                      }, {} as Record<string, string>);

                      setEntries(newEntries);
                    } else setEntries({});
                  }}
                  multiple
                  mode="inline"
                  required
                >
                  {Object.entries(dependenciesParamOptions)?.map(
                    ([key, val]) => (
                      <LdOption
                        key={key}
                        value={key}
                        selected={entries[key] != undefined}
                      >
                        {val.parameterName}
                      </LdOption>
                    )
                  )}
                </LdSelect>
                <div className="mb-6"></div>
              </LdLabel>
            </div>
          )}

        {parameterType &&
          parameterSubType &&
          rows.length > 0 &&
          !ConstraintDiscreteSubtypeSimple.includes(parameterSubType) && (
            <div className="w-full">
              <LdTable>
                <LdTableHead>
                  <LdTableRow>
                    <LdTableHeader>
                      {parameterSubType ==
                      ConstraintsSubTypeDiscrete.DiscretePermutationInvarianceConstraint
                        ? 'Dependencies Parameters'
                        : 'Parameters'}
                    </LdTableHeader>
                    <LdTableHeader>
                      {parameterType == ConstraintsType.continuous
                        ? 'Coefficient'
                        : 'Conditions'}
                    </LdTableHeader>
                  </LdTableRow>
                </LdTableHead>
                <LdTableBody>
                  {rows.map(([key, value]) =>
                    value ? (
                      <LdTableRow key={`${key}-constr}`}>
                        <LdTableCell className="w-2/5">
                          <LdTypo
                            variant="body-s"
                            className="max-w-[10rem]"
                            style={{ 'overflow-wrap': 'anywhere' }}
                          >
                            {value}
                          </LdTypo>
                        </LdTableCell>
                        {parameterType == ConstraintsType.discrete &&
                          parameterSubType ==
                            ConstraintsSubTypeDiscrete.DiscreteDependenciesConstraint && (
                            <LdTableCell className="w-2/5">
                              <LdTypo
                                variant="body-s"
                                className="max-w-[10rem]"
                                style={{ 'overflow-wrap': 'anywhere' }}
                              >
                                {affectedParamters?.[key]?.join(' ,')}
                              </LdTypo>
                            </LdTableCell>
                          )}
                        <LdTableCell className="w-3/5">
                          {parameterType == ConstraintsType.continuous ? (
                            <LdTypo
                              variant="body-s"
                              className="max-w-[10rem]"
                              style={{ 'overflow-wrap': 'anywhere' }}
                            >
                              {coefficients?.[key]}
                            </LdTypo>
                          ) : conditions?.[key]?.type ==
                            'SubSelectionCondition' ? (
                            <div className="flex  border p-1 mx-auto border-collapse flex-col rounded-m  border-rich-purple-dark">
                              <LdLabel>Sub Selection Condition</LdLabel>
                              <div className="flex flex-row">
                                {conditions?.[key] != undefined &&
                                  (
                                    conditions?.[key] as SubSelectionCondition
                                  )?.selection?.map((e) => (
                                    <LdTypo
                                      key={e}
                                      variant="label-m"
                                      className="mx-1"
                                      style={{ 'overflow-wrap': 'anywhere' }}
                                    >
                                      <LdBadge className="ld-badge--info">
                                        {e}
                                      </LdBadge>
                                    </LdTypo>
                                  ))}
                              </div>
                            </div>
                          ) : (
                            <div className="flex  border p-1 mx-auto border-collapse flex-col rounded-m border-rich-purple-dark">
                              <LdLabel>Threshold Condition</LdLabel>
                              <div className="flex flex-row justify-between">
                                <LdTypo
                                  variant="body-s"
                                  className="max-w-[10rem]"
                                  style={{ 'overflow-wrap': 'anywhere' }}
                                >
                                  Operator:{' '}
                                  <LdBadge className="ld-badge--info">
                                    {
                                      (conditions?.[key] as ThresholdCondition)
                                        ?.operator
                                    }
                                  </LdBadge>
                                </LdTypo>
                                <LdTypo
                                  variant="body-s"
                                  className="max-w-[10rem]"
                                  style={{ 'overflow-wrap': 'anywhere' }}
                                >
                                  Threshold:{' '}
                                  {
                                    (conditions?.[key] as ThresholdCondition)
                                      ?.threshold
                                  }
                                </LdTypo>
                                <LdTypo
                                  variant="body-s"
                                  className="max-w-[10rem]"
                                  style={{ 'overflow-wrap': 'anywhere' }}
                                >
                                  Tolerance:{' '}
                                  {(conditions?.[key] as ThresholdCondition)
                                    ?.tolerance || 'None'}
                                </LdTypo>
                              </div>
                            </div>
                          )}
                        </LdTableCell>
                        <LdTableCell className="w-1/5">
                          <LdButton
                            type="button"
                            onClick={() => removeConstraints(key)}
                            mode="danger"
                            size="sm"
                          >
                            <LdIcon name="cross" />
                          </LdButton>
                        </LdTableCell>
                      </LdTableRow>
                    ) : null
                  )}
                </LdTableBody>
              </LdTable>
            </div>
          )}

        {parameterType &&
          parameterSubType &&
          ConstraintDiscreteSubtypeSimple.includes(
            parameterSubType as ConstraintsSubTypeDiscrete
          ) && (
            <div>
              <div className="flex flex-wrap gap-ld-4 mb-ld-4">
                {Object.entries(entries)?.map(([key, val]) => {
                  return <Tag key={key}>{val}</Tag>;
                })}
              </div>
              <LdLabel className="flex flex-grow">
                Parameters
                <LdSelect
                  tetherOptions={tetherOptions}
                  placeholder="Select parameters"
                  name="parameters"
                  onLdinput={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();

                    const keys: string[] = ev.detail;
                    if (keys?.length > 0) {
                      const newEntries = keys.reduce((acc, k) => {
                        acc[k] = valueOptions[k].parameterName;
                        return acc;
                      }, {} as Record<string, string>);

                      setEntries(newEntries);
                    } else {
                      setEntries({});
                    }

                    setConditions({});
                    setPermutationParams({});
                  }}
                  multiple
                  mode="inline"
                  required
                >
                  {Object.entries(valueOptions)?.map(([key, val]) => (
                    <LdOption
                      key={key}
                      value={key}
                      selected={entries[key] != undefined}
                    >
                      {val.parameterName}
                    </LdOption>
                  ))}
                </LdSelect>
                <div className="mb-6"></div>
              </LdLabel>
            </div>
          )}
      </form>

      {parameterType &&
        parameterSubType &&
        !ConstraintDiscreteSubtypeSimple.includes(parameterSubType) && (
          <ConstraintsValueForm
            subType={parameterSubType}
            valueOptions={valueOptions}
            onSubmit={addConstraintValue}
            type={parameterType}
            tetherOptions={tetherOptions}
            selectedParams={selectedParams}
          />
        )}

      {parameterType == ConstraintsType.discrete &&
        parameterSubType ==
          ConstraintsSubTypeDiscrete.DiscreteExcludeConstraint && (
          <>
            <div className="grid grid-cols-2 md:grid-cols-2 gap-ld-24 mb-3">
              <LdLabel>
                <span className="flex justify-between">Combiner</span>
                <LdSwitch
                  size="sm"
                  required
                  onLdswitchchange={(event) =>
                    setCombiner(event.detail as CombinerType)
                  }
                >
                  {Object.keys(CombinerType).map((val) => (
                    <LdSwitchItem
                      key={val}
                      value={val}
                      checked={combiner == val}
                    >
                      {val}
                    </LdSwitchItem>
                  ))}
                </LdSwitch>
              </LdLabel>
            </div>
          </>
        )}

      {parameterType == ConstraintsType.continuous && (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-ld-24 mb-3">
            <LdLabel>
              <span className="flex justify-between">Operator</span>
              <LdSwitch
                required
                onLdswitchchange={(e) => {
                  setOperator(e.detail);
                }}
                placeholder="Select Sub Type"
              >
                {Object.keys(ConstraintContinuousOperatorType).map((val) => (
                  <LdSwitchItem key={val} value={val} checked={operator == val}>
                    {val}
                  </LdSwitchItem>
                ))}
              </LdSwitch>
            </LdLabel>

            <LdLabel>
              Right-Hand Side
              <LdInput
                required
                placeholder="e.g. 3"
                type="number"
                tone="dark"
                onInput={(ev) => {
                  const v = (ev.target as HTMLLdInputElement).value;
                  v ? setRhs(Number(v)) : setRhs(undefined);
                }}
                value={rhs?.toString()}
              />
            </LdLabel>
          </div>
        </>
      )}

      {parameterType &&
        parameterSubType &&
        (parameterSubType == ConstraintsSubTypeDiscrete.DiscreteSumConstraint ||
          parameterSubType ==
            ConstraintsSubTypeDiscrete.DiscreteProductConstraint) && (
          <SingleTresholdConditionForm
            entries={entries}
            onSubmit={updateSingleCondition}
            conditions={conditions}
          />
        )}

      <div
        slot="footer"
        className="flex flex-row items-end justify-end gap-x-ld-8"
      >
        <div className="flex flex-row items-end justify-end gap-x-ld-8">
          <LdButton
            onClick={handleCancel}
            mode="danger-secondary"
            type="button"
          >
            Cancel
          </LdButton>
          <LdButton
            disabled={!selectedParams}
            form="parameter-form"
            name="btn-submit-params"
            onClick={(e) => handleFormSubmit(e)}
          >
            Submit
          </LdButton>
        </div>
      </div>
    </div>
  );
};

export default AddConstraintsForm;
