/* eslint-disable import/named */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  LdButton,
  LdInput,
  LdInputMessage,
  LdLabel,
  LdOption,
  LdSelect,
  LdTable,
  LdTableBody,
  LdTableCell,
  LdTableRow,
} from '@emdgroup-liquid/liquid/dist/react';
import { ReactComponent as IconPlus } from 'assets/static/images/icon-plus.svg';
import {
  ConstraintsSubTypeDiscrete,
  ConstraintsType,
  DiscreteConditions,
  ParameterType,
} from 'types/api/types';
import { ParametersObject } from 'types/app/types';
import { ConditionsValueForm } from './ConditionsValueForm';

type FormData = {
  paramValue?: string;
};

interface ParameterValuesFormProps {
  onSubmit: (val: Record<string, any>) => void;
  type?: string | undefined;
  subType: string;
  valueOptions: ParametersObject;
  tetherOptions: any;
  selectedParams: ParametersObject;
}

export const ConstraintsValueForm: React.FC<ParameterValuesFormProps> = ({
  onSubmit,
  type,
  valueOptions,
  tetherOptions,
  subType,
  selectedParams,
}) => {
  const condRef = useRef<HTMLFormElement>(null);
  const { formState, clearErrors, setError, trigger } = useForm<FormData>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });
  const { errors } = formState;
  const [entries, setEntries] = useState<Record<string, string | undefined>>(
    {}
  ); // paramter names
  const [affectedParamters, setAffectedParamters] = useState<
    Record<string, string[] | undefined>
  >({});
  const [coefficients, setCoefficients] = useState<
    Record<string, number | undefined>
  >({});

  const handleFormInvalid = () => {
    setAffectedParamters({});
    setCoefficients({});
    setEntries({});
  };

  const affectedParams = useMemo(() => {
    if (!selectedParams) return {};
    return Object.fromEntries(
      Object.entries(selectedParams).filter(
        ([key, value]) =>
          value.parameterType !== ParameterType.continuous &&
          value.parameterType !== ParameterType.task &&
          !Object.values(entries).includes(value.parameterName)
      )
    );
  }, [selectedParams, entries]);

  const handleSubmit = async () => {
    if (type === ConstraintsType.continuous) {
      if (
        !Object.values(coefficients).length ||
        !Object.values(entries).length
      ) {
        setError('paramValue', {
          type: 'Parameters and coefficients are required!',
        });
        return;
      }
      if (Object.values(coefficients).length != Object.values(entries).length) {
        setError('paramValue', {
          type: 'Number of coefficients and number of selected paramters should be equal!',
        });
        return;
      }
      clearErrors('paramValue');
      const valid = await trigger();
      if (!valid) return;

      onSubmit({
        entries,
        coefficients,
      });
      handleFormInvalid();
    } else {
      await condRef?.current?.clickButton(async (data: DiscreteConditions) => {
        const conditions: Record<string, DiscreteConditions> = {
          [Object.keys(entries)?.[0]]: data,
        };

        if (
          !Object.values(conditions).length ||
          !Object.values(entries).length
        ) {
          setError('paramValue', {
            type: 'Parameters and conditions are required!',
          });
          return;
        }
        if (Object.values(entries).length != Object.values(conditions).length) {
          setError('paramValue', {
            type: 'Number of conditions and number of selected paramters should be equal!',
          });
          return;
        }

        if (
          subType == ConstraintsSubTypeDiscrete.DiscreteDependenciesConstraint
        ) {
          if (!Object.values(affectedParamters).length) {
            setError('paramValue', {
              type: 'Affected Paramters are required!',
            });
            return;
          }
        }
        clearErrors('paramValue');
        const valid = await trigger();
        if (!valid) return;

        onSubmit({
          entries,
          conditions,
          affectedParamters,
        });
        handleFormInvalid();
      });
    }
  };

  const isDisabledAdd = useMemo(() => {
    if (type == ConstraintsType.continuous) {
      return Object.values(coefficients).length == 0;
    }
    if (type == ConstraintsType.discrete) {
      return Object.values(entries).length == 0;
    }
  }, [type, coefficients, entries]);

  const addCoefficients = (ev: React.FormEvent<HTMLLdInputElement>): void => {
    const val = (ev.target as HTMLLdInputElement).value;
    if (val != undefined) {
      setCoefficients((old) => ({
        ...old,
        [Object.keys(entries)?.[0]]: Number(val),
      }));
    } else {
      delete coefficients[Object.keys(entries)?.[0]];
      setCoefficients(coefficients);
    }
  };

  return (
    <>
      <form
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onKeyDown={(e) => {
          e.key === 'Enter' && e.preventDefault();
        }}
      >
        <div className="flex left-0 right-0 mb-1">
          <div className="flex flex-col flex-grow">
            {type !== undefined && (
              <LdTable>
                <LdTableBody>
                  <LdTableRow>
                    {
                      <LdTableCell className="min-w-max w-1/2">
                        <div className="flex flex-col">
                          {
                            <LdLabel>
                              {subType ==
                              ConstraintsSubTypeDiscrete.DiscretePermutationInvarianceConstraint
                                ? 'Dependent On:'
                                : 'Parameter'}
                              <LdSelect
                                disabled={
                                  Object.values(valueOptions).length == 0
                                }
                                key={'param-key'}
                                required
                                placeholder="Select Parameter"
                                name={'param'}
                                onLdinput={(ev) => {
                                  const key: string = ev.detail[0];
                                  const val = valueOptions[key];
                                  if (val != undefined)
                                    setEntries({
                                      [key]: val.parameterName,
                                    });
                                  else {
                                    setEntries({});
                                  }
                                  setAffectedParamters({});
                                }}
                                mode="inline"
                                tetherOptions={tetherOptions}
                                className="w-72"
                              >
                                {Object.entries(valueOptions).map(
                                  ([key, val]) => (
                                    <LdOption
                                      key={key}
                                      value={key}
                                      selected={entries[key] != undefined}
                                    >
                                      {val.parameterName}
                                    </LdOption>
                                  )
                                )}
                              </LdSelect>
                            </LdLabel>
                          }
                          {type == ConstraintsType.discrete &&
                            subType ==
                              ConstraintsSubTypeDiscrete.DiscreteDependenciesConstraint && (
                              <LdLabel className="mt-2">
                                Affected Parameters
                                <LdSelect
                                  mode="inline"
                                  filter
                                  multiple
                                  style={{ maxWidth: '18rem' }}
                                  maxRows={2}
                                  className="w-72"
                                  disabled={
                                    Object.values(entries).length == 0 ||
                                    Object.values(affectedParams).length == 0
                                  }
                                  key={'param-key-affected'}
                                  required
                                  placeholder="Select Affected Parameter"
                                  name={'param-affected'}
                                  onLdinput={(ev) => {
                                    const keys: string[] = ev.detail;
                                    if (!keys?.length) return;
                                    const vals = keys?.map(
                                      (k) => affectedParams[k].parameterName
                                    );
                                    if (vals != undefined)
                                      setAffectedParamters({
                                        [Object.keys(entries)?.[0]]: vals,
                                      });
                                    else {
                                      setAffectedParamters({});
                                    }
                                  }}
                                  tetherOptions={tetherOptions}
                                >
                                  {Object.entries(affectedParams).map(
                                    ([key, val]) => (
                                      <LdOption
                                        key={key}
                                        value={key}
                                        selected={affectedParamters[
                                          Object.keys(entries)?.[0]
                                        ]?.includes(val.parameterName)}
                                      >
                                        {val.parameterName}
                                      </LdOption>
                                    )
                                  )}
                                </LdSelect>
                              </LdLabel>
                            )}
                        </div>
                      </LdTableCell>
                    }
                    <LdTableCell className="min-w-max w-1/2">
                      {type == ConstraintsType.continuous && (
                        <>
                          <div className="flex flex-wrap gap-ld-4">
                            <LdLabel>
                              Coefficient Value
                              <LdInput
                                className="w-72"
                                name={'coeff'}
                                disabled={Object.keys(entries).length == 0}
                                required
                                placeholder="e.g. 100"
                                type="number"
                                tone="dark"
                                onInput={addCoefficients}
                                value={coefficients[
                                  Object.keys(entries)?.[0]
                                ]?.toString()}
                              />
                            </LdLabel>
                          </div>
                        </>
                      )}
                      {type == ConstraintsType.discrete && (
                        <div className="flex flex-wrap gap-ld-4">
                          <ConditionsValueForm
                            ref={condRef}
                            paramter={valueOptions[Object.keys(entries)?.[0]]}
                            name="Conditions"
                            type={type}
                            tetherOptions={tetherOptions}
                            subType={subType}
                          />
                        </div>
                      )}
                    </LdTableCell>
                    <LdTableCell className="align-top">
                      <div className="flex flex-wrap self-center justify-items-center justify-center">
                        <LdButton
                          disabled={isDisabledAdd}
                          name="btn-add-param"
                          onClick={handleSubmit}
                          type="button"
                          mode="secondary"
                          className="mb-ld-12 ml-2 h-ld-32 mt-3"
                        >
                          <IconPlus className="text-rich-purple" />
                        </LdButton>
                      </div>
                    </LdTableCell>
                  </LdTableRow>
                </LdTableBody>
              </LdTable>
            )}

            <LdInputMessage
              className={
                errors.paramValue && errors.paramValue.type !== 'required'
                  ? 'visible'
                  : 'invisible'
              }
              mode={errors.paramValue ? 'error' : 'valid'}
            >
              {errors.paramValue?.type || 'Value is required.'}
            </LdInputMessage>
          </div>
        </div>
      </form>
    </>
  );
};
