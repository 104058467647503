import * as yup from 'yup';

import {
  ConstraintContinuousOperatorType,
  ConstraintsSubTypeDiscrete,
  ConstraintsType,
  DiscreteConstraint,
  DiscreteConstraintDependencies,
  StringArray,
  ThresholdCondition,
} from 'types/api/types';
import {
  AcquisitionFunctionType,
  CombinerType,
  ConditionsType,
  ConstraintsSubTypeContinuous,
  CustomParameterValue,
  DiscreteConditions,
  ExperimentResults,
  InitialGuessMethodType,
  Parameter,
  ParameterRecommendation,
  ParameterType,
  SmilesParameterValue,
  SurrogateModelType,
} from '../api/types';

export interface ParameterFormEntry {
  id: string;
  value?: string | SmilesParameterValue | CustomParameterValue | number[];
}

export interface ParametersObject {
  [key: string]: Parameter;
}
export interface ConstraintObject {
  [key: string]: ConstraintFormItem & {
    coefficients?: Record<string, number | undefined>;
    parameters: Record<string, string>;
    conditions?: Record<string, DiscreteConditions | undefined>;
    affectedParamters?: Record<string, string[] | undefined>;
    dependencies?: {
      parameters: Record<string, string>;
      conditions?: Record<string, DiscreteConditions | undefined>;
      affectedParamters?: Record<string, string[] | undefined>;
    };
  };
}

// TODO: check if the enum ParameterType will work for E2E
export type ParameterTypeString =
  | 'Number'
  | 'Substance'
  | 'Category'
  | 'Custom'
  | 'Continuous'
  | 'Task';

export interface Feedback {
  subject?: string;
  message?: string;
}

export interface Bug {
  title?: string;
  steps_to_reproduce?: string;
  expected_behavior?: string;
  system_information?: string;
  attachment?: {
    name: string;
    data: string;
  };
}

export interface BugFormData {
  title?: string;
  stepsToReproduce?: string;
  expectedBehavior?: string;
  systemInformation?: string;
  attachment?: File;
}

export enum QuestionType {
  star = 'star',
  text = 'text',
}

export interface FeedbackQuestion {
  questionId: string;
  text: string;
  questionType: keyof typeof QuestionType;
  isMandatory: boolean;
}

export interface FeedbackProduct {
  productId: string;
  name: string;
  description: string;
  createdAt: string;
  questions: {
    [key: string]: FeedbackQuestion;
  };
}

export enum TargetObjective {
  Max = 'Max',
  Min = 'Min',
  Match = 'Match',
}

export enum StateStatus {
  idle = 'idle',
  loading = 'loading',
  succeeded = 'succeeded',
  failed = 'failed',
}

export interface ExperimentResult {
  recommendationId?: string;
  parameters: ParameterRecommendation;
  results: ExperimentResults;
}

export const smilesValidationResponseScheme = yup.object({
  success: yup.boolean().required(),
  error: yup.string().nullable(),
  valid: yup.boolean().nullable(),
  smiles: yup.string().nullable(),
});

export const SurroGateModelLabel = new Map<string, string>([
  [SurrogateModelType.GP, 'Gaussian Process'],
  [SurrogateModelType.RF, 'Random Forrest'],
  [SurrogateModelType.Linear, 'Linear Bayesian'],
]);

export const InitialGuessMethodLabel = new Map<string, string>([
  [InitialGuessMethodType.rand, 'Random'],
  [InitialGuessMethodType.pam, 'Partition Around Medoids (PAM)'],
  [InitialGuessMethodType.kmeans, 'K-Means'],
]);

export const AcquisitionFunctionLabel = new Map<string, string>([
  [AcquisitionFunctionType.EI, 'Expected Improvement'],
  [AcquisitionFunctionType.PI, 'Probability of Improvement'],
  [AcquisitionFunctionType.UCB, 'Upper Confidence Bound'],
  [AcquisitionFunctionType.qLogNEI, 'Noisy Expected Improvement'],
  [AcquisitionFunctionType.qSR, 'Simple Regret'],
  [AcquisitionFunctionType.qNIPV, 'Integrated Posterior Variance'],
]);

export const ParameterTypesLabel = new Map<string, string>([
  [ParameterType.smiles, 'Substance'],
  [ParameterType.category, 'Category'],
  [ParameterType.numeric, 'Number'],
  [ParameterType.custom, 'Custom'],
  [ParameterType.continuous, 'Continuous'],
  [ParameterType.task, 'Task'],
]);

export const ConstraintTypesLabel = new Map<string, string>([
  [ConstraintsType.continuous, 'Continuous'],
  [ConstraintsType.discrete, 'Discrete'],
]);

export type ConstraintFormItem = {
  parameters: Record<string, string>;
  affectedParamters?: Record<string, string[] | undefined>;
  coefficients?: Record<string, number | undefined>;
  type: ConstraintsType;
  constraintId: string;
  projectId?: string;
  rhs?: number;
  subType?: ConstraintsSubTypeContinuous | ConstraintsSubTypeDiscrete;
  combiner?: CombinerType;
  conditions?: Record<string, DiscreteConditions | undefined>;
  operator: ConstraintContinuousOperatorType;
  dependencies?: {
    type: 'DiscreteDependenciesConstraint';
    parameters: Record<string, string>;
    affectedParamters: Record<string, string[] | undefined>;
    conditions: Record<string, DiscreteConditions | undefined>;
  };
};

export type ConstraintSingleConditionType = DiscreteConstraint & {
  condition?: ThresholdCondition;
};
export type ConstraintDiscreteDependenciesType = DiscreteConstraint & {
  affected_parameters?: StringArray[];
};

export type ConstraintDiscretePermutationType = DiscreteConstraint & {
  dependencies: DiscreteConstraintDependencies & {
    affected_parameters?: StringArray[];
  };
};

export const ConstraintSubTypesLabel = new Map<
  string,
  ConstraintsSubTypeContinuous[] | ConstraintsSubTypeDiscrete[]
>([
  [
    ConstraintsType.continuous,
    [ConstraintsSubTypeContinuous.ContinuousLinearConstraint],
  ],
  [
    ConstraintsType.discrete,
    [
      ConstraintsSubTypeDiscrete.DiscreteNoLabelDuplicatesConstraint,
      ConstraintsSubTypeDiscrete.DiscreteLinkedParametersConstraint,
      ConstraintsSubTypeDiscrete.DiscreteProductConstraint,
      ConstraintsSubTypeDiscrete.DiscreteSumConstraint,
      ConstraintsSubTypeDiscrete.DiscreteExcludeConstraint,
      ConstraintsSubTypeDiscrete.DiscreteDependenciesConstraint,
      ConstraintsSubTypeDiscrete.DiscretePermutationInvarianceConstraint,
    ],
  ],
]);

export const ConstraintDiscreteSubtypeSimple: string[] = [
  ConstraintsSubTypeDiscrete.DiscreteNoLabelDuplicatesConstraint,
  ConstraintsSubTypeDiscrete.DiscreteLinkedParametersConstraint,
  ConstraintsSubTypeDiscrete.DiscreteProductConstraint,
  ConstraintsSubTypeDiscrete.DiscreteSumConstraint,
];

export const ConstraintsSubtypLabels = {
  [ConstraintsSubTypeDiscrete.DiscreteNoLabelDuplicatesConstraint]:
    'No Label Duplicate',
  [ConstraintsSubTypeDiscrete.DiscreteLinkedParametersConstraint]:
    'Linked Parameters',
  [ConstraintsSubTypeDiscrete.DiscreteProductConstraint]: 'Product',
  [ConstraintsSubTypeDiscrete.DiscreteSumConstraint]: 'Sum',
  [ConstraintsSubTypeDiscrete.DiscreteExcludeConstraint]: 'Exclude',
  [ConstraintsSubTypeContinuous.ContinuousLinearConstraint]: 'Linear',
  [ConstraintsSubTypeDiscrete.DiscreteDependenciesConstraint]: 'Dependencies',
  [ConstraintsSubTypeDiscrete.DiscretePermutationInvarianceConstraint]:
    'Permutation Invariance',
};

export const ConditionTypesLabel = new Map<string, string>([
  [ConditionsType.subselection, 'Sub Selection'],
  [ConditionsType.threshold, 'Threshold'],
]);

export const TargetObjectivesLabel = new Map<string, string>([
  [TargetObjective.Max, 'Max'],
  [TargetObjective.Min, 'Min'],
  [TargetObjective.Match, 'Match'],
]);

export const roleFilterItems = [
  'createdByMe',
  'Owner',
  'Editor',
  'Viewer',
] as const;

export type RoleFilter = typeof roleFilterItems[number];

export class FormError extends Error {
  field: string;

  description?: string;

  constructor(field: string, msg: string) {
    super(msg);
    this.field = field;
  }
}
